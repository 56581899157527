/**
 * Set Local Storage
 *
 * @param key {string}
 * @param value {object|string|array}
 *
 */
const setStorage = (key: string, value: any): void => {
  window.localStorage.setItem(
    key,
    typeof value !== 'string' ? JSON.stringify(value) : value,
  )
}

/**
 * Get Local Storage
 *
 * @param key {string}
 * @param defaultValue {object|string|array}
 *
 * @returns {object|string|array}
 *
 */
const getStorage = (key: string, defaultValue: any = null): any => {
  try {
    const value = window.localStorage.getItem(key)

    try {
      if (!value) {
        return defaultValue
      }

      // If the value can't be parsed as an object...
      return JSON.parse(value)
    } catch (error) {
      // ... then return the value as-is
      return value
    }
  } catch (error) {
    console.error('Utilities/getStorage', { error, key })

    return defaultValue
  }
}

/**
 * Remove Local Storage
 *
 * @param key {string}
 *
 *
 */
const removeStorage = (key: string): void => {
  window.localStorage.removeItem(key)
}

export default {
  setStorage,
  getStorage,
  removeStorage,
}
