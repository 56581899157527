import React, { useEffect, useState } from 'react'
import ClassNames from 'classnames'
import { Spin } from 'antd'
import styles from './styles.module.scss'

type Props = {
  visible: boolean
  timeout?: number
  section?: boolean
  obscured?: boolean
}

const componentId = 'components/loaders'

const defaultProps: Props = {
  visible: false,

  // Duration before loader is shown

  timeout: 250,

  // set to 'false' for a full page loader

  // set to 'true' for a loader that is absolutely-positioned to
  // the nearest relative container (good for drawers and sections within a page)

  section: false,

  // set to 'true' for a completely white background to obscure the page

  obscured: false,
}

const PageLoader = (props: Props): JSX.Element => {
  const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    /**
     * Show loader only after a certain timeout
     *
     * @type {number}
     */
    const timer = setTimeout(() => {
      setShowLoader(true)
    }, props.timeout)

    if (!props.visible) {
      timer && clearTimeout(timer)
      setShowLoader(false)
    }

    return () => {
      timer && clearTimeout(timer)
    }
  }, [props.visible])

  return (
    <div
      className={ClassNames(styles.spin, {
        [styles.section]: props.section,
        [styles.obscured]: props.obscured,
        [styles.show]: showLoader,
      })}
      data-component={componentId}
    >
      <Spin />
    </div>
  )
}

PageLoader.defaultProps = defaultProps

export default PageLoader
