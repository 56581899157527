// const componentId = 'utiltities/files/misc'
/**
 * Create a random, 10 character string
 *
 * @returns {string}
 *
 */
function randomString(): string {
  return Math.random().toString(36).substring(2, 12)
}

function removeObjectByIndex(obj: any, index: number) {
  const keys = Object.keys(obj)
  if (index < 0 || index >= keys.length) {
    return obj
  }
  const newObj: any = {}
  for (let i = 0; i < keys.length; i++) {
    if (i !== index) {
      newObj[keys[i]] = obj[keys[i]]
    }
  }
  return newObj
}

function isArrayWithOnlyNumbers(input: any[]) {
  if (Array.isArray(input) && input.length > 0) {
    // Check if every element in the array is a number
    return input.every((item) => typeof item === 'number')
  } else {
    // If it's not an array or an empty array, return false
    return false
  }
}

export default {
  randomString,
  removeObjectByIndex,
  isArrayWithOnlyNumbers,
}
