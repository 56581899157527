// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React, { Component, Suspense, useContext } from 'react'
import {
  MethodsContext,
  MethodsModel,
  StateContext,
  StateModel,
} from '@/context'

/** ALL GLOBAL STYLESHEETS **/
import './styles/imports.scss'
import AppRoutes from './appRoutes'
import PageLoader from './components/loaders'
import { LocalStorage, Log } from '@/utilities'
import { BreakpointProvider } from '@/hooks/breakpoints'
import Breakpoints from './utilities/files/breakpoints'
import { RtsProfileModel } from '@/models/rts/user'

const componentId = 'app'

class App extends Component<MethodsModel, StateModel> {
  private readonly methods = {} as MethodsModel
  private readonly setStateAsync: (state: any) => Promise<unknown>

  constructor(props: any) {
    super(props)

    this.state = {
      // Current page
      page: [],
      // RTS User Profile
      profile: {} as RtsProfileModel,
      loading: false,
      options: {
        roles: [],
        visaTypes: [],
        technologies: [],
        clients: [],
        positions: [],
        accounts: [],
        teams: [],
      },
      initializing: false,
      idToken: '',
    }

    // Common methods allowed to be called throughout the APP
    this.methods = {
      /**
       * Converts React setState into promise-based function
       * to update any global/context state values
       * @param state {object|function}
       *
       */
      setStateAsync: async (state) => {
        return new Promise((resolve) => {
          this.setState(state, () => {
            resolve(null)
          })
        })
      },
      // Set active page dynamically
      setPage: this.setPage,
      // Global loader
      setLoading: this.setLoading,
      // Set Roles
      setRoles: this.loadRoles,
      // Load Roles
      loadRoles: this.loadRoles,
      // Clear the profile
      clearProfile: this.clearProfile,
    }

    // Convenience function to setState as a promise
    this.setStateAsync = (state) =>
      new Promise((resolve) => {
        this.setState(state, () => {
          resolve(null)
        })
      })
  }

  componentDidMount() {
    this.initializePage()
    Log({ componentId, action: 'componentDidMount', data: {} })
  }

  componentDidUpdate(
    prevProps: Readonly<MethodsModel>,
    prevState: Readonly<StateModel>,
  ) {
    if (this.state.profile !== prevState.profile) {
      this.setState({ profile: this.state.profile })
    }
    if (this.state.idToken !== prevState.idToken) {
      this.setState({ idToken: this.state.idToken })
    }
    Log({
      componentId,
      action: 'componentDidUpdate',
      data: { state: this.state },
    })
  }

  initializePage = async () => {
    const currentRtsUser = await LocalStorage.getStorage('rtsUser')
    const allVisaTypes = await LocalStorage.getStorage('allVisaTypes')
    const allTechnologies = await LocalStorage.getStorage('allTechnologies')
    const allClients = await LocalStorage.getStorage('allClients')
    const allAccounts = await LocalStorage.getStorage('allAccounts')
    const allPositions = await LocalStorage.getStorage('allPositions')
    const allTeams = await LocalStorage.getStorage('allTeams')
    const allSkills = await LocalStorage.getStorage('allSkills')

    this.setState({
      profile: currentRtsUser,
      options: {
        visaTypes: allVisaTypes,
        roles: this.loadRoles(),
        technologies: allTechnologies,
        clients: allClients,
        positions: allPositions,
        accounts: allAccounts,
        teams: allTeams,
        skills: allSkills,
      },
    })
  }

  /**
   * Load all the roles in the global methods context
   *
   */
  loadRoles = () => {
    return [
      { label: 'Admin', value: 'ADMIN' },
      { label: 'Account Manager', value: 'ACC_MGR' },
      { label: 'Team Leader', value: 'TL' },
      { label: 'Recruiter', value: 'RECRUITER' },
      { label: 'HR Manager', value: 'HR_MANAGER' },
    ]
  }

  /**
   * Clear the profile from the global state
   *
   */
  clearProfile = () => {
    this.setState({ profile: null })
    Log({
      componentId,
      action: 'clearProfile',
      data: { profile: this.state.profile },
    })
  }

  /**
   * Set Page dynamically. Used to make the navigation
   * menu display the correct active menu.  Used specifically for
   * links off of the home page.
   *
   * @param page {array}
   *
   */
  setPage = async (page: string[]) => {
    await this.setStateAsync({ page })
  }

  /**
   * Page Loader indicator
   *
   * @param loading {boolean}
   */
  setLoading = async (loading: boolean) => {
    await this.setStateAsync({ loading })
  }

  render() {
    return (
      <MethodsContext.Provider value={this.methods}>
        <StateContext.Provider value={this.state}>
          <BreakpointProvider queries={Breakpoints}>
            <Suspense fallback={<PageLoader visible={true} />}>
              <AppRoutes />
            </Suspense>
          </BreakpointProvider>
        </StateContext.Provider>
      </MethodsContext.Provider>
    )
  }
}

export default App
