/**
 * All breakpoint settings for the Breakpoint observer hook
 *
 */

const Breakpoints = {
  xs: '(max-width: 575px)',
  sm: '(min-width: 576px) and (max-width: 767px)',
  md: '(min-width: 768px) and (max-width: 991px)',
  lg: '(min-width: 992px) and (max-width: 1199px)',
  xl: '(min-width: 1200px) and (max-width: 1599px)',
  xxl: '(min-width: 1599px)',
  landscape: '(orientation: landscape)',
  portrait: '(orientation: portrait)',
}

export default Breakpoints
