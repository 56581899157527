import { useLocation, Navigate, Outlet } from 'react-router-dom'
import useAuth from '@/hooks/useAuth'

const RequiredAuth = ({ allowedRoles }: any) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { auth } = useAuth()
  const location = useLocation()

  return auth?.roles?.find((role: string) => allowedRoles?.includes(role)) ? (
    <Outlet />
  ) : auth?.user ? (
    <Navigate to="/unauthorized" state={{ from: location }} replace />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  )
}

export default RequiredAuth
