import React from 'react'
import { RtsProfileModel } from './models/rts/user'
import {
  Roles,
  VisaTypesModel,
  TechnologiesModel,
  AccountsModel,
  PositionsModel,
} from './models/shared'

// Global Methods
interface MethodsModel {
  setStateAsync?: (state: any) => Promise<any>
  setLoading?: (loading: boolean) => Promise<void>
  setPage?: (page: string[]) => void
  setDivision?: (division: number) => Promise<any>
  isAdmin?: () => boolean
  scrollTo?: (top: number, smooth?: boolean) => void
  setRoles?: () => void
  loadRoles?: () => any
  clearProfile?: () => void
}

// Global State
interface StateModel {
  profile: RtsProfileModel
  page: string[]
  options: {
    roles: Roles[]
    visaTypes: VisaTypesModel[]
    technologies: TechnologiesModel[]
    clients: any
    accounts: AccountsModel[]
    positions: PositionsModel[]
    teams: any
    skills: any
  }
  loading: boolean
  initializing: boolean
  idToken: string
}

const MethodsContext = React.createContext({} as MethodsModel)
const StateContext = React.createContext({} as StateModel)
export { MethodsContext, StateContext }
export type { MethodsModel, StateModel }
