import { useContext } from 'react'
import { Auth } from 'aws-amplify'
import useAuth from '@/hooks/useAuth'
import { StateContext } from '@/context'
import { LocalStorage, Log } from '@/utilities'

const componentId = 'hooks/useRefreshToken'
const useRefreshToken = () => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setAuth } = useAuth()
  const globalState = useContext(StateContext)

  const refresh = async () => {
    const response: any = await Auth.currentSession()
    const currentRtsUser = LocalStorage.getStorage('rtsUser')

    console.log('gs', globalState)

    setAuth((prev: any) => {
      // TODO: The roles needs to be enhanced further
      const roles = currentRtsUser?.role
      const user = currentRtsUser
      Log({
        componentId,
        action: 'useRefreshToken',
        data: { roles: roles, prev: prev, currentRtsUser },
      })
      return {
        ...prev,
        user,
        roles: [roles],
        idToken: response.idToken.jwtToken,
      }
    })

    return response.idToken.jwtToken
  }
  return refresh
}

export default useRefreshToken
