import { Outlet, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import useRefreshToken from '@/hooks/useRefreshToken'
import useAuth from '@/hooks/useAuth'
import PageLoader from '@/components/loaders'

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true)
  const refresh = useRefreshToken()
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { auth } = useAuth()
  const navigate = useNavigate()

  useEffect((): any => {
    const verifyRefreshToken = async () => {
      try {
        await refresh()
      } catch (error) {
        console.error(error)
        if (error === 'No current user') {
          navigate('/login')
        }
      } finally {
        setIsLoading(false)
      }
    }

    !auth.idToken ? verifyRefreshToken() : setIsLoading(false)
  }, [])

  return <>{isLoading ? <PageLoader visible={isLoading} /> : <Outlet />}</>
}

export default PersistLogin
