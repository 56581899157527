// https://betterprogramming.pub/how-to-use-media-queries-programmatically-in-react-4d6562c3bc97

import React, { useState, useEffect, createContext, useContext } from 'react'
import { Breakpoints } from '@/utilities'

const componentId = 'hooks/breakpoints'

const defaultValue = Breakpoints

const BreakpointContext = createContext(defaultValue)

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const BreakpointProvider = ({ children, queries }) => {
  const [queryMatch, setQueryMatch] = useState({} as any)

  // Log({ componentId, action: 'BreakpointProvider', data: { queries } });

  useEffect(() => {
    const mediaQueryLists = {}
    const keys = Object.keys(queries)
    let isAttached = false
    console.log(componentId)

    const handleQueryListener = () => {
      const updatedMatches = keys.reduce((acc, media) => {
        acc[media] = !!(
          mediaQueryLists[media] && mediaQueryLists[media].matches
        )
        return acc
      }, {})
      setQueryMatch(updatedMatches)
    }

    if (window && window.matchMedia) {
      const matches = {}
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media] = window.matchMedia(queries[media])
          matches[media] = mediaQueryLists[media].matches
        } else {
          matches[media] = false
        }
      })
      setQueryMatch(matches)
      isAttached = true
      keys.forEach((media) => {
        if (typeof queries[media] === 'string') {
          mediaQueryLists[media].addListener(handleQueryListener)
        }
      })
    }

    return () => {
      if (isAttached) {
        keys.forEach((media) => {
          if (typeof queries[media] === 'string') {
            mediaQueryLists[media].removeListener(handleQueryListener)
          }
        })
      }
    }
  }, [queries])

  return (
    <BreakpointContext.Provider value={queryMatch}>
      {children}
    </BreakpointContext.Provider>
  )
}

function useBreakpoint() {
  const context = useContext(BreakpointContext)
  if (context === Breakpoints) {
    throw new Error('useBreakpoint must be used within BreakpointProvider')
  }
  return context
}

export { useBreakpoint, BreakpointProvider }
