/**
 * Simple Logger
 * this.log('Location of log source', .... as many other elements as you want);
 * example: this.log('App.js',{data},'some other value');
 *
 * @param page {string}
 * @param action {string}
 * @param data {object|string|array}
 * @param isError {boolean}
 *
 */
export default function Log({
  componentId = '',
  action = '',
  data = {},
  isError = false,
} = {}): void {
  if (isError) {
    console.error(`[RTS] ${componentId}`, action, data)
  } else {
    console.log(`[RTS] ${componentId}`, action, data)
  }
}
