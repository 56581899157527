import React, { useContext } from 'react'
import { Routes, Route } from 'react-router-dom'
import { StateContext } from './context'
import Layout from '@/components/layout/Layout'
import RequiredAuth from '@/components/auth/RequiredAuth'
import PersistLogin from '@/components/auth/PersistLogin'
import Logout from '@/components/auth/Logout'

// ALL PAGES
const Home = React.lazy(() => import('@/pages/home'))
const Login = React.lazy(() => import('@/pages/login'))
const SignUp = React.lazy(() => import('@/pages/signup'))
const ForgotPassword = React.lazy(
  () => import('src/pages/signup/forgot-password'),
)
const ChangePassword = React.lazy(() => import('@/pages/change-password'))
const Requirements = React.lazy(() => import('@/pages/requirements'))
const AddRequirements = React.lazy(() => import('@/pages/requirements/add'))
const EditRequirements = React.lazy(() => import('@/pages/requirements/add'))
const ViewRequirement = React.lazy(() => import('@/pages/requirements/view'))
const Unauthorized = React.lazy(() => import('@/pages/unauthorized'))
// const ManageUsers = React.lazy(() => import('@/pages/admin/manage/users'))
const Submissions = React.lazy(() => import('@/pages/submissions'))
const AddSubmissions = React.lazy(() => import('@/pages/submissions/add'))
const EditSubmissions = React.lazy(() => import('@/pages/submissions/edit'))
const Search = React.lazy(() => import('@/pages/search'))
const ViewDiceCandidate = React.lazy(() => import('@/pages/search/dice/view'))
const ViewRtsCandidate = React.lazy(() => import('@/pages/search/rts/view'))
const Admin = React.lazy(() => import('@/pages/admin/manage'))
const AddClient = React.lazy(() => import('@/pages/admin/manage/clients/add'))
const UpdateClient = React.lazy(
  () => import('@/pages/admin/manage/clients/update'),
)
const Candidates = React.lazy(() => import('@/pages/candidates'))
const EmailBlast = React.lazy(() => import('@/pages/email-blast'))
const Distro = React.lazy(() => import('@/pages/distro'))
const AddDistro = React.lazy(() => import('@/pages/distro/add'))
const EditDistro = React.lazy(() => import('@/pages/distro/edit'))
const ManageDistroAccess = React.lazy(() => import('@/pages/distro/access'))
const Help = React.lazy(() => import('@/pages/help'))

function AppRoutes() {
  // Make sure the page is initialized in app.tsx BEFORE rendering
  // any other pages.  This check allows for supporting API calls to finish
  // before trying to render a page.
  const globalState = useContext(StateContext)
  if (globalState.initializing) {
    return null
  }

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* ------ PUBLIC ROUTES ------ */}
        <Route path={`/login`} element={<Login />} />
        <Route path={`/signup`} element={<SignUp />} />
        <Route path={`/signup/forgot`} element={<ForgotPassword />} />
        <Route path={`/unauthorized`} element={<Unauthorized />} />
        <Route path={`/help`} element={<Help />} />

        {/* ------ ALL PROTECTED ROUTES WITH PERSIST LOGIN  ------ */}
        <Route element={<PersistLogin />}>
          <Route path={`/`} element={<Home />} />
          <Route path={`/requirements`} element={<Requirements />} />
          <Route
            path={`/requirements/view/:id`}
            element={<ViewRequirement />}
          />
          {/* ------ PROTECTED ROUTES ADMIN, RECRUITERS AND TL ------ */}
          {globalState?.profile?.role !== 'RECRUITER' ? (
            <Route
              element={
                <RequiredAuth allowedRoles={['ADMIN', 'ACC_MGR', 'TL']} />
              }
            >
              {/* ---- REQUIREMENTS ---- */}
              <Route path={`/requirements/add`} element={<AddRequirements />} />
              <Route
                path={`/requirements/edit/:id`}
                element={<EditRequirements />}
              />
              {/* ---- SEARCH ---- */}
              <Route path={`/search/:type?`} element={<Search />} />
              <Route
                path={`/search/rts/view/:id`}
                element={<ViewRtsCandidate />}
              />
              <Route
                path={`/search/dice/view/:id`}
                element={<ViewDiceCandidate />}
              />
              <Route
                path={`/search/dice/view/:id/:type?`}
                element={<ViewDiceCandidate />}
              />

              {/* ---- CANDIDATES ---- */}
              <Route path={`/candidates`} element={<Candidates />} />
              <Route path={`/candidates/:type?`} element={<Candidates />} />
              <Route path={`/candidates/:type?/:id`} element={<Candidates />} />

              {/* ---- EMAIL BLAST ---- */}
              <Route path={`/email`} element={<EmailBlast />} />

              {/* ---- MANAGE DISTRO ---- */}
              <Route path={`/distro`} element={<Distro />} />
              <Route path={`/distro/add`} element={<AddDistro />} />
              <Route path={`/distro/edit/:id`} element={<EditDistro />} />
              <Route
                path={`/distro/manage/access/:id`}
                element={<ManageDistroAccess />}
              />

              {/* ---- SUBMISSIONS ---- */}
              <Route path={`/submissions`} element={<Submissions />} />
              <Route
                path={`/submissions/add/:id`}
                element={<AddSubmissions />}
              />
              <Route
                path={`/submissions/edit/:id`}
                element={<EditSubmissions />}
              />
              <Route path={`/logout`} element={<Logout />} />
              <Route path={`/change/password`} element={<ChangePassword />} />
            </Route>
          ) : (
            <Route element={<RequiredAuth allowedRoles={['RECRUITER']} />}>
              {/* ---- SUBMISSIONS ---- */}
              <Route path={`/submissions`} element={<Submissions />} />
              <Route
                path={`/submissions/add/:id`}
                element={<AddSubmissions />}
              />
              <Route
                path={`/submissions/edit/:id`}
                element={<EditSubmissions />}
              />
              {/* ---- SEARCH ---- */}
              <Route path={`/search/:type?`} element={<Search />} />
              <Route
                path={`/search/rts/view/:id`}
                element={<ViewRtsCandidate />}
              />
              <Route
                path={`/search/dice/view/:id`}
                element={<ViewDiceCandidate />}
              />
              <Route
                path={`/search/dice/view/:id/:type?`}
                element={<ViewDiceCandidate />}
              />

              {/* ---- CANDIDATES ---- */}
              <Route path={`/candidates`} element={<Candidates />} />
              <Route path={`/candidates/:type?`} element={<Candidates />} />
              <Route path={`/candidates/:type?/:id`} element={<Candidates />} />

              {/* ---- SUBMISSIONS ---- */}
              <Route path={`/submissions`} element={<Submissions />} />
              <Route
                path={`/submissions/add/:id`}
                element={<AddSubmissions />}
              />
              <Route
                path={`/submissions/edit/:id`}
                element={<EditSubmissions />}
              />
              <Route path={`/logout`} element={<Logout />} />
              <Route path={`/change/password`} element={<ChangePassword />} />
            </Route>
          )}

          {/* ------ PROTECTED ROUTES ADMIN ------ */}
          <Route element={<RequiredAuth allowedRoles={['ADMIN', 'ACC_MGR']} />}>
            <Route path={`/admin/manage/:type?`} element={<Admin />} />
            <Route path={`/admin/manage/:type?/add`} element={<AddClient />} />
            <Route
              path={`/admin/manage/:type?/update/:id`}
              element={<UpdateClient />}
            />
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default AppRoutes
