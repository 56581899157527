import { useContext, useEffect, useState } from 'react'
import { LocalStorage } from '@/utilities'
import { Auth } from 'aws-amplify'
import { useNavigate } from 'react-router-dom'
import PageLoader from '@/components/loaders'
import useAuth from '@/hooks/useAuth'
import { MethodsContext } from '@/context'

const Logout = () => {
  const navigate = useNavigate()
  const globalMethods = useContext(MethodsContext)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { setAuth } = useAuth()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const removeAllStorage = async () => {
      try {
        setAuth({})
        LocalStorage.removeStorage('rtsUser')
        LocalStorage.removeStorage('allVisaTypes')
        LocalStorage.removeStorage('allTechnologies')
        LocalStorage.removeStorage('allClients')
        LocalStorage.removeStorage('allPositions')
        LocalStorage.removeStorage('allAccounts')
        LocalStorage.removeStorage('allTeams')
        await Auth.signOut()
        globalMethods.clearProfile()
      } catch (error) {
        console.log(error)
      } finally {
        setIsLoading(false)
      }
    }

    removeAllStorage().then(() => {
      navigate('/login')
    })
  }, [])

  return <>{isLoading && <PageLoader visible={isLoading} />}</>
}

export default Logout
