import moment from 'moment-timezone'
import { Amplify, Auth } from 'aws-amplify'
import { LocalStorage, Log } from '@/utilities'

const componentId = 'utilities/files/auth'

Amplify.configure({
  Auth: {
    identityPoolId: 'us-east-1:9bdb7b46-5b4c-48a1-8045-708644451e92',
    region: 'us-east-1',
    userPoolId: 'us-east-1_RfCqdJCaZ',
    userPoolWebClientId: '10jk3ud374p885beogff2eatst',
  },
  Storage: {
    AWSS3: {
      bucket: 'rts-prod-uploads',
      region: 'us-east-1',
    },
  },
})

async function login(user: string, password: string) {
  const loggedUser = Auth.signIn({
    username: user,
    password: password,
  })

  return loggedUser
}

async function resendCode(email: string) {
  return Auth.resendSignUp(email)
}

async function confirmUser(username: string, code: string) {
  const user = await Auth.confirmSignUp(username, code)
  return user
}

async function forgotPassword(username: string) {
  const user = await Auth.forgotPassword(username)
  return user
}

async function forgotPasswordSubmit(
  username: string,
  code: string,
  password: string,
) {
  const user = await Auth.forgotPasswordSubmit(username, code, password)
  return user
}

async function changePassword(oldPassword: string, newPassword: string) {
  const user = await Auth.currentAuthenticatedUser()
  const data = await Auth.changePassword(user, oldPassword, newPassword)
  return data
}

/**
 * Go to Logout page
 *
 */
function redirectToLogout() {
  clearTokens()

  Log({ componentId, action: 'redirectToLogout', data: 'logout' })
}

function validateToken() {
  const response = { isAuthenticated: false }
  const tokenCheck = checkToken()

  Log({ componentId, action: 'validateToken', data: { tokenCheck } })

  try {
    if (tokenCheck.hasToken && !tokenCheck.hasExpired) {
      response.isAuthenticated = true
    } else {
      return response
    }
  } catch (error) {
    Log({
      componentId,
      action: 'validateToken',
      data: { error },
      isError: true,
    })

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    throw new Error(error)
  }
}

/**
 * Returns information about the validity of the token
 *
 */
function checkToken(): {
  minutesToExpiration: number
  expirationTime: string
  hasToken: boolean
  hasExpired: boolean
} {
  const rtsjwttoken = LocalStorage.getStorage('rtsjwttoken', '')
  const expires = LocalStorage.getStorage('rtsjwttoken_expires', '')

  const minutesToExpiration = expires
    ? +moment(expires).diff(moment(), 'minutes')
    : 0
  const expirationTime = moment().add(minutesToExpiration, 'minutes').format()

  const hasToken = !!rtsjwttoken
  const hasExpired = minutesToExpiration <= 0

  return {
    minutesToExpiration,
    expirationTime,
    hasToken,
    hasExpired,
  }
}

/**
 * Remove all Authentication tokens from Local Storage
 *
 */
function clearTokens() {
  LocalStorage.removeStorage('rtsjwttoken')
  LocalStorage.removeStorage('rtsjwttoken_expires')
}

async function getIdToken() {
  const currentAuthenticatedUser: any = await Auth.currentAuthenticatedUser()
  return currentAuthenticatedUser.signInUserSession.idToken.jwtToken
}

export default {
  login,
  checkToken,
  validateToken,
  clearTokens,
  redirectToLogout,
  getIdToken,
  resendCode,
  confirmUser,
  forgotPassword,
  forgotPasswordSubmit,
  changePassword,
}
